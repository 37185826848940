<template>
  <div class="container-fluid">
    <div class="card nw-card mt-2">
      <div class="card-header nw-card-header text-left">
        <h4>Survey - {{ survey.name }}</h4>
      </div>
      <div class="card-body text-left">
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label class="control-label">Survey Title</label>
              <input class="form-control" v-model="survey.name"  type="text"/>
            </div>
            <div class="form-group">
              <label class="control-label">Watch Prompt</label>
              <textarea class="form-control" v-model="survey.prompt" rows="2"></textarea>
            </div>
            <div class="form-group">
              <label class="control-label">Instructions</label>
              <textarea class="form-control" v-model="survey.instructions" rows="2"></textarea>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="control-label">Recurring survey?</label><br/>
              <toggle class="mt-2" v-model="survey.recurring" color="green" on-text="On" off-text="Off"></toggle>
            </div>
            <div class="form-group">
              <label class="control-label">Prompt retail patients?</label><br/>
              <toggle class="mt-2" v-model="survey.includeRetail" color="green" on-text="On" off-text="Off"></toggle>
            </div>
            <div class="form-group">
              <label class="control-label">Prompt clinical trial pateints?</label><br/>
              <toggle class="mt-2" v-model="survey.includeClinical" color="green" on-text="On" off-text="Off"></toggle>
            </div>
          </div>
        </div>

        <div class="row border-top">
          <div class="col-6 border-right">
            <div class="h4 text-center  mt-2">Questions</div>
            <button class="btn nw-btn-outline-info"><i class="fa fa-plus"></i> Create New Question<br/></button>
            <table class="table table-hover mt-4">
              <thead><tr><th>Order</th><th>Question</th><th>Type</th></tr></thead>
              <tbody>
                <tr v-for="item in survey.arr">
                  <td>{{ item.order}}</td>
                  <td>{{ item.question}}</td>
                  <td>{{ qtype(item.type)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 border-right">
            <div class="h4 text-center  mt-2">Cadence</div>
            <button class="btn nw-btn-outline-info"><i class="fa fa-plus"></i> Add Interval<br/></button>
            <table class="table table-hover mt-4">
              <thead><tr><th>Wait Period</th><th>Unit</th><th>Repeat</th></tr></thead>
              <tbody>
                <tr v-for="item in survey.cadence">
                  <td>{{ item.start}}</td>
                  <td>{{ qunit(item.unit)}}</td>
                  <td>{{ qrepeat(item.repeat)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <div class="card nw-card mt-2">
          <div class="card-header nw-card-header text-left">
            <h4>Question Defintion</h4>
          </div>
          <div class="card-body text-left">
            <div class="form-group">
              <label class="control-label">Question</label>
              <input class="form-control" v-model="survey.arr[0].question"  type="text"/>
            </div>
            <div class="form-group">
              <label class="control-label">Type</label>
              <select class="form-control" v-model="survey.arr[0].type">
                <option value="NPS">Net Promoter Score</option>
                <option value="MC">Multiple Choice</option>
                <option value="TXT">Text</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card nw-card mt-2">
          <div class="card-header nw-card-header text-left">
            <h4>Question Defintion</h4>
          </div>
          <div class="card-body text-left">
            <div class="form-group">
              <label class="control-label">Question</label>
              <input class="form-control" v-model="survey.arr[0].question"  type="text"/>
            </div>
            <div class="form-group">
              <label class="control-label">Type</label>
              <select class="form-control" v-model="survey.arr[0].type">
                <option value="NPS">Net Promoter Score</option>
                <option value="MC">Multiple Choice</option>
                <option value="TXT">Text</option>
              </select>
            </div>
            <button class="btn nw-btn-outline-info"><i class="fa fa-plus"></i> Create New Answer<br/></button>
            <table class="table table-hover mt-2">
            <tbody>
              <tr v-for="item in survey.arr[0].answers"><td>{{ item.msg}}</td></tr>


            </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card nw-card mt-2">
          <div class="card-header nw-card-header text-left">
            <h4>Question Defintion</h4>
          </div>
          <div class="card-body text-left">
            <div class="form-group">
              <label class="control-label">Question</label>
              <input class="form-control" v-model="survey.arr[2].question"  type="text"/>
            </div>
            <div class="form-group">
              <label class="control-label">Type</label>
              <select class="form-control" v-model="survey.arr[2].type">
                <option value="NPS">Net Promoter Score</option>
                <option value="MC">Multiple Choice</option>
                <option value="TXT">Text</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>


  </div>
</template>

<script>
import Toggle from '@/components/Toggle'

export default {
  components: { Toggle},
  data () {
    return {
      survey: {
        name: 'User Experience Survey',
        repeatable: true,
        instructions: 'Please tell us about your experience with NightWare',
        prompt: 'Please access the NightWare app on the iPhone to complete an important survey.',
        includeRetail: true,
        includeClinical: false,
        enabled: true,
        recurring: true,
        cadence: [ {start: 1, unit: 'w', repeat: 1}, {start: 1, unit: 'm', repeat: -1} ],
        arr: [
          { question: 'Would you recommend?', type: 'NPS', order: 1, answers: [
            { msg: '1 - I would not recommend', aid: '1'},
            { msg: '2', aid: '2'},
            { msg: '3', aid: '3'},
            { msg: '4', aid: '3'},
            { msg: '5', aid: '3'},
            { msg: '6', aid: '3'},
            { msg: '7', aid: '3'},
            { msg: '8', aid: '3'},
            { msg: '9', aid: '3'},
            { msg: '10 - I would highly recommend', aid: '3'}
          ]},
          { question: 'Are you better?', type: 'MC', order: 2, answers: [{ msg: 'Not better', aid: '1'},{ msg: 'Same', aid: '2'},{ msg: 'Better', aid: '3'}]},
          { question: 'What could we do better?', type: 'TXT', order: 3, answers: []}
        ]
      }
    }
  },
  methods: {
    qrepeat(_id) {
        if(_id == 1) return 'Send Once'
        else if(_id == -1) return 'Indefinitely'
        else return _id +  ' attempts'
    },
    qunit(_id) {
      switch(_id)
      {
        case 'd':
          return 'Day'
        case 'm':
          return 'Month'
        case 'w':
          return 'Week'
        case 'y':
          return "year"
        default:
          return _id
      }
    },
    qtype (_id)
    {
      switch(_id)
      {
        case 'NPS':
          return 'Net Promoter Score'
        case 'MC':
          return 'Multiple Choice'
        case 'TXT':
          return "Text"
        default:
          return _id
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
